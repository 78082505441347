// src/QRScanner.js
import React, { useState, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import { fetchEventPlayerInfo, updateAttendance } from "../services";
import { isMobile, isTablet } from "react-device-detect"; // Optional: For device detection

const QRScanner = () => {
    const [processing, setProcessing] = useState(false),
        [player, setPlayer] = useState({}),
        [event, setEvent] = useState({}),
        [id, setId] = useState(null)
    const [result, setResult] = useState(null);
    const [hasPermission, setHasPermission] = useState(null);
    const [cameraKey, setCameraKey] = useState(Date.now()); // Key to force re-render
    const [error, setError] = useState(null);

    const handleScan = (data) => {
        if (data) {
            setResult(data.text);
            setProcessing(true)
        }
    }

    const getCameraConstraints = () => {
        if (isMobile || isTablet) {
            return { facingMode: "environment" }; // Rear camera for mobile/tablet
        } else {
            return { facingMode: "user" }; // Front camera for desktop
        }
    };

    //   await updatePlayerAttendance({ attended: 1 }, token);

    const displayInfo = async (token) => {
        try {
            let info = await fetchEventPlayerInfo(token)

            if (info) {
                setPlayer(info.player[0])
                setEvent(info.event[0])
                setId(info.id)
            }
            else {
                alert("QR code seems to be corrupted")
            }

            setProcessing(false)

        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message);
            setProcessing(false)
        }
    }

    const attendance = async () => {
        try {
            await updateAttendance({ attended: 1 }, id)

            alert("Event attendance updated")
            setProcessing(false)
            setPlayer({})
            setEvent({})
            setId(null)
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message);
            setProcessing(false)
        }
    }

    useEffect(() => {
        if (result) {
            // Update corresponding user attendance
            displayInfo(result)
        }
    }, [result])

    useEffect(() => {
        // Check camera permissions
        navigator.mediaDevices
            .getUserMedia({ video: true }) // Request access to the video stream
            .then(() => setHasPermission(true))
            .catch((err) => {
                setHasPermission(false);
                setError("Camera access denied. Please enable camera permissions.");
            });
    }, []);

    const handleError = (err) => {
        console.error("QR Scanner Error:", err);
        setError("Failed to initialize camera. Please try again.");
    };

    const previewStyle = {
        height: "100%",
        width: "100%",
    };

    // Force unmount and remount the camera component
    const restartCamera = () => {
        // setCameraKey(Date.now()); // Update the key to force re-render
        // setError(null); // Clear any previous errors

        navigator.mediaDevices
            .getUserMedia({ video: getCameraConstraints() }) // Request access to the video stream
            .then(() => {
                setCameraKey(Date.now()); // Update the key to force re-render
                setError(null); // Clear any previous errors
            })
            .catch((err) => {
                console.error("Failed to restart camera:", err);
                setError("Failed to restart camera. Please try again.");
            });
    };


    if (hasPermission === null) {
        return <p>Requesting camera permission...</p>;
    }

    if (hasPermission === false) {
        return <p>Camera access denied. Please enable camera permissions.</p>;
    }

    return (
        <div>
            <h1 className="text-center">QR Code Scanner</h1>
            {
                processing
                    ?
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>

                    :
                    <div className="d-flex flex-column justify-content-center">
                        {
                            id

                                ?

                                <>
                                    <label>Event: {event?.title}</label>
                                    <hr />
                                    <label>Player: {player?.firstname} {player?.lastname}</label>
                                    <hr />
                                    <label>Phone number: {player?.phone_number}</label>
                                    <br /><br />
                                    <button
                                        className="btn btn-success"
                                        onClick={attendance}
                                    >
                                        <span className="fa fa-check"></span>&nbsp;Mark attended
                                    </button>
                                </>

                                :
                                <>
                                    <button className="btn btn-sm btn-danger mb-2" onClick={restartCamera}>Restart Camera</button>
                                    {error && <p style={{ color: "red" }}>{error}</p>}
                                    <QrScanner
                                        key={cameraKey} // Force re-render by changing the key
                                        delay={300} // Delay between scans (in milliseconds)
                                        onError={handleError}
                                        onScan={handleScan}
                                        style={previewStyle}
                                        // constraints={{ facingMode: "environment" }} // Use the rear camera
                                        constraints={{ video: getCameraConstraints() }}
                                    />
                                </>
                        }
                    </div>
            }
        </div>
    );
};

export default QRScanner;