import React from "react";
import { useQuery } from "react-query";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import {
    fetchTiers,
    updateTier
} from "../../services";
import { Box, Chip } from "@mui/material";

const Tiers = () => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "n_plays",
      headerName: "# Plays",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            color={`${params.row.active ? `success` : `error`}`}
            label={`${params.row.active ? `Active` : `Inactive`}`}
          />
        );
      },
    },
    {
      field: "formatted_date_add",
      headerName: "Created On",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-tier/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className={`btn btn-sm bg-gradient-${
                params.row.active ? `danger` : `success`
              }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${params.row.active ? `Deactivate` : `Activate`}`}
              onClick={() => updateStatus(params.row.id, params.row.active)}
            >
              <i className={`fa fa-${params.row.active ? `ban` : `check`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery("tiers", () => fetchTiers(), {
    select: (data) => data.tiers,
    refetchOnWidowFoucs: false,
  });

  const updateStatus = async (tierId, active) => {
    try {
      await updateTier({ active: +!active }, tierId);
      alert("Tier has been successfully updated");
      window.location.reload();
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Tiers</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
          </div>
        </div>

        <div className="content">
          {/* <div className="container"> */}
          <div className="d-flex flex-row align-items-center mb-3">
            <a href="/new-tier" className="btn bg-gradient-primary mr-3">
              <i className="fa fa-plus mr-1" /> New tier
            </a>
          </div>

          <Box sx={{ height: "80vh", width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Tiers;
