import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchExperienceMessages, updateExperience } from "../../services/";
import QRScanner from "../../components/QRScanner";

const Scanner = () => {
  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              {/* <h1 className="m-0">QR Scanner</h1> */}
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <Box sx={{ height: "80vh", width: "50%" }}>
              <QRScanner />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Scanner;
