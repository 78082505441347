import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchTestimonials, updateTestimonial } from "../../services/";
import Select from "react-select";
import { useParams } from "react-router-dom";

const EditTestimonial = () => {
  const { id } = useParams()
  const [preview, setPreview] = useState(null)

  const saveChanges = async (values) => {
    try {
      await updateTestimonial(values, id);
      alert("Testimonial has been successfully updated");
      window.location.href = "/testimonials";
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  const { data, isLoading } = useQuery("info", () => fetchTestimonials(id), {
    select: (data) => data.testimonials[0],
    refetchOnWindowFocus: false
  })

  if (isLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit testimonial</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/testimonials">Testimonials</a>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 className="card-title">Testimonial info</h3>
                  </div>

                  <Formik
                    initialValues={{
                      photo: data.photo,
                      title: data.title,
                      content: data.content
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      photo: Yup.string().required(),
                      title: Yup.string().required(),
                      content: Yup.string().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            {/* <div className="form-group"> */}
                              <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Testimonial title</label>

                                        <input
                                            // autoComplete="off"
                                            type="text"
                                            name="title"
                                            placeholder="Testimonial title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            className="form-control"
                                        />
                                        {errors.title && touched.title && (
                                            <span className="text-danger text-sm">
                                            {errors.title}
                                            </span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Content</label>

                                  <textarea
                                    rows="5"
                                    name="content"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Testimonial content"
                                    className="form-control"
                                  >{data.content}</textarea>
                                  {errors.content && touched.content && (
                                    <span className="text-danger text-sm">
                                      {errors.content}
                                    </span>
                                  )}
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <label>Photo</label><br />
                                    <img src={preview ? preview : data.photo} height="200" />
                                    <br /><br />

                                    <input type="file" onChange={(e) => {
                                        setPreview(URL.createObjectURL(e.target.files[0]))
                                        setFieldValue("photo", e.currentTarget.files[0]);
                                    }} name="photo" />

                                    {errors.photo && touched.photo && (
                                        <span className="text-danger text-sm">
                                        {errors.photo}
                                        </span>
                                    )}
                                </div>
                              </div>
                            {/* </div> */}
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-warning"
                              disabled={isSubmitting}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditTestimonial;
