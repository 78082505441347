import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { addTestimonial } from "../../services/";

const AddTestimonial = () => {
  const saveChanges = async (values) => {
    try {
      await addTestimonial(values);
      alert("Testimonial has been successfully created");
      window.location.href = "/testimonials";
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New testimonial</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Testimonial info</h3>
                  </div>

                  <Formik
                    initialValues={{
                      title: "",
                      content: "",
                      photo: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      // console.log(values)
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string().required(),
                      content: Yup.string().required(),
                      photo: Yup.mixed().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Title</label>

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="title"
                                    placeholder="Testimonial Title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title}
                                    className="form-control"
                                  />
                                  {errors.title && touched.title && (
                                    <span className="text-danger text-sm">
                                      {errors.title}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Photo</label><br />
                                  <input type="file" onChange={(e) => {
                                    setFieldValue("photo", e.currentTarget.files[0]);
                                  }} name="photo" />

                                  {errors.photo && touched.photo && (
                                    <span className="text-danger text-sm">
                                      {errors.photo}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Content</label>
                              <textarea
                                className="form-control"
                                placeholder="Testimonial content"
                                name="content"
                                rows={5}
                                value={values.content}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.content && touched.content && (
                                <span className="text-danger text-sm">
                                  {errors.content}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-primary"
                              disabled={isSubmitting}
                            >
                              Add Testimonial
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestimonial;
