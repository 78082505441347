import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchEvents, updateEvent, duplicateEvent, refundPlayer, sendEmail } from "../../services/";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Chip } from "@mui/material";
import Select from "react-select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Events = () => {
  const [duplicateModal, setDuplicateModal] = useState(false),
  [playersModal, setPlayersModal] = useState(false),
    [activeEvent, setActiveEvent] = useState(null),
    [filters, setFilters] = useState({})

    const { data, isLoading, refetch } = useQuery("events", () => fetchEvents(filters), {
      select: (data) => data.events,
      refetchOnWindowFocus: false,
    });

  const updateStatus = async (eventId, active) => {
    try {
      await updateEvent({ active: !active }, eventId);
      alert("Event has been successfully updated");
      window.location.reload();
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  const refund = async (playerId) => {
    try {
      await refundPlayer(activeEvent.id, playerId)
      alert("Event fees has been successfully loaded into player's balance")
      refetch()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  }

  const duplicate = async (data, eventId) => {
    try {
      let newEvent = await duplicateEvent(
        {
          start_date: data.start_date,
          end_date: data.end_date,
        },
        eventId
      );
      alert("Event has been successfully duplicated");
      window.location.href = `/edit-event/${newEvent.id}`;
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  const clearFilter = (key) => {
    let filtersClone = {...filters}
    delete filtersClone[key]
    setFilters(filtersClone)
  }

  const columns = [
    {
      field: "title",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.35,
      renderCell: (params) => {
        let is_upcoming = new Date(params.row.raw_start_date) > new Date(),
        is_past = new Date(params.row.end_date) < new Date()

        return <Chip color={`${is_upcoming ? `warning` : (is_past ? `error` : `success`)}`} size="small" label={`${is_upcoming ? `Upcoming` : (is_past ? `Past` : `Ongoing`)}`} />
      }
    },
    {
      field: "start_date",
      headerName: "Starts On",
      flex: 0.6,
    },
    {
      field: "end_date",
      headerName: "Ends On",
      flex: 0.6,
    },
    {
      field: "n_tickets",
      headerName: "# Seats",
      flex: 0.25,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <span>
            EGP{" "}
            {Number(params.row.price)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        );
      },
    },
    {
      field: "area_name",
      headerName: "Area",
      flex: 0.35,
    },
    // {
    //   field: "location_link",
    //   headerName: "Maps",
    //   flex: 0.7,
    // },
    {
      field: "date_add",
      headerName: "Created On",
      flex: 0.6,
    },
    // {
    //   field: "modified",
    //   headerName: "Last Edited",
    //   flex: 0.6,
    // },
    {
      field: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <a
              className="btn btn-sm bg-gradient-primary mr-2"
              href={`/gallery/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Gallery"
            >
              <i className="fa fa-image" />
            </a>

            <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-event/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className={`mr-2 btn btn-sm bg-gradient-${
                params.row.active ? `danger` : `success`
              }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${params.row.active ? `Deactivate` : `Activate`}`}
              onClick={() => updateStatus(params.row.id, params.row.active)}
            >
              <i className={`fa fa-${params.row.active ? `ban` : `check`}`} />
            </button>

            <button
              className="btn btn-sm bg-gradient-info mr-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Players"
              onClick={() => {
                setPlayersModal(true);
                setActiveEvent(params.row);
              }}
            >
              <i className="fa fa-running" />
            </button>

            <button
              className={`btn btn-sm bg-gradient-default mr-2`}
              data-toggle="tooltip"
              data-placement="top"
              title={`Duplicate`}
              onClick={() => {
                setDuplicateModal(true);
                setActiveEvent(params.row);
              }}
            >
              <i className={`fa fa-clone`} />
            </button>

            <a
              className={`btn btn-sm bg-gradient-warning`}
              data-toggle="tooltip"
              data-placement="top"
              title={`Questions`}
              href={`/event-questions/${params.row.id}`}
            >
              <i className={`fa fa-question`} />
            </a>
          </div>
        );
      },
    },
  ];

  const playersColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{`${params.row.firstname} ${params.row.lastname}`}</span>;
      },
    },
    {
      field: "email",
      headerName: "E-mail address",
      flex: 0.7,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      flex: 0.5,
    },
    {
      field: "voucher",
      headerName: "Voucher",
      flex: 0.3,
      renderCell: (params) => {
        return <span>{params.voucher_id ? `Yes` : `-`}</span>
      }
    },
    {
      field: "attended",
      headerName: "Attendance",
      flex: 0.3,
      renderCell: (params) => {
        return <span><Chip size="small" color={params.attended ? `success` : `error`} label={params.attended ? `Attended` : `Absent`} /></span>
      }
    },
    // {
    //   field: "payment",
    //   headerName: "Payment",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     return <Chip size="small" color={`${params.row.is_refunded ? `error` : `default`}`} label={`${params.row.is_refunded ? `REFUNDED` : `---`}`} />
    //   }
    // },
    {
      field: "source",
      headerName: "Payment method",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{params.row.voucher_id ? `Voucher` : `Card`}</span>
      }
    },
    {
      field: "price",
      headerName: "Amount paid",
      flex: 0.5,
      renderCell: (params) => {
        return <span>EGP {params.row.price}</span>
      }
    },
    {
      field: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-admin/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className={`btn btn-sm bg-gradient-${
                params.row.is_refunded ? `success` : `danger`
              }`}
              data-toggle="tooltip"
              data-placement="top"
              // title={`${params.row.is_refunded ? `Un-refund` : `Refund`}`}
              title={`Refund`}
              onClick={() => refund(params.row.player_id)}
            >
              <i className={`fa fa-${params.row.is_refunded ? `redo` : `undo`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    refetch()
  },[filters, refetch])

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <BootstrapDialog
        onClose={() => {
          setDuplicateModal(false);
        }}
        fullScreen={true}
        aria-labelledby="customized-dialog-title"
        open={duplicateModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Enter dates for the new duplicate event
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDuplicateModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="card card-warning">
          {/* <div className="card-header">
            <h3 className="card-title">
              Change {activeUser?.firstname} {data.lastname}'s Password
            </h3>
          </div> */}

          <Formik
            initialValues={{
              start_date: "",
              end_date: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              duplicate(values, activeEvent.id);
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              start_date: Yup.date().required(),
              end_date: Yup.date()
                .min(
                  Yup.ref("start_date"),
                  "An event end date cannot be before start date"
                )
                .required(),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label style={{ display: "block" }}>Start Date</label>

                      <DatePicker
                        className="form-control"
                        placeholder="Starts on"
                        style={{ width: "100% !important" }}
                        selected={values.start_date}
                        onChange={(date) => {
                          setFieldValue("start_date", date);
                        }}
                      />
                      {errors.start_date && touched.start_date && (
                        <span className="text-danger text-sm">
                          {errors.start_date}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label style={{ display: "block" }}>End Date</label>

                      <DatePicker
                        selected={values.end_date}
                        className="form-control"
                        placeholder="Ends on"
                        onChange={(date) => {
                          setFieldValue("end_date", date);
                        }}
                      />
                      {errors.end_date && touched.end_date && (
                        <span className="text-danger text-sm">
                          {errors.end_date}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="card-footer">
                    <button
                      type="submit"
                      className="btn bg-gradient-info"
                      disabled={isSubmitting}
                    >
                      Duplicate event
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={() => {
          setPlayersModal(false);
        }}
        fullScreen={true}
        aria-labelledby="customized-dialog-title"
        open={playersModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <i className="text-muted">{activeEvent?.title}</i> players <small className="text-small text-muted">({activeEvent?.players.length} results)</small>
          <br /> <br />

          {
            activeEvent?.players.length ? 

            <button
                className="btn bg-gradient-info"
                // disabled={!selectedRows?.length}
                onClick={() => {
                  sendEmail({ event_id: activeEvent?.id })
                  alert("Email sent successfully")
                }}
              >
                <i className="fa fa-envelope mr-2" /> Send location email
              </button>

              :

              null
          }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setPlayersModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="card card-warning">
          {/* <div className="card-header">
            <h3 className="card-title">
              Change {activeUser?.firstname} {data.lastname}'s Password
            </h3>
          </div> */}
          <div style={{ height: "100vh" }}>
            <DataGrid
              columns={playersColumns}
              rows={activeEvent?.players}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </div>
        </div>
      </BootstrapDialog>

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Events</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                {/* <li className="breadcrumb-item active">Dashboard</li> */}
              </ol>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
          <div className="d-flex flex-row align-items-center mb-3">
            <a href="/new-event" className="btn bg-gradient-primary mr-3">
              <i className="fa fa-plus mr-1" /> New event
            </a>
          </div>

          <div className="row mb-3">
            <div className="col-lg-3">
              <Select
                options={[
                  { label: "Upcoming", value: "is_upcoming" },
                  { label: "Past", value: "is_past" },
                  { label: "Ongoing", value: "is_ongoing" },
                ]}
                placeholder="Filter by type"
                isClearable
                onChange={(value, triggeredAction) => {
                  if (value && value.value) setFilters({...filters, type: value.value})

                  if (triggeredAction.action === "clear") {
                    clearFilter("type")
                  }
                }}
              />
            </div>
          </div>

          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
            />
          </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Events;
