import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer, } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchVouchers, updateVoucher } from "../../services/";
import { Chip } from "@mui/material";

const Vouchers = () => {
  const updateStatus = async (voucherId, active) => {
    try {
      await updateVoucher({ active: + !active }, voucherId);
      alert("Voucher has been successfully updated");
      window.location.reload();
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "code",
      headerName: "Promocode",
      flex: 0.5,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 0.5,
      renderCell: (params) => {
        let value = params.row.is_percent ? params.row.value : Number(params.row.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return <span>{params.row.is_percent ? `` : `EGP`} {value}{params.row.is_percent ? `%` : ``}</span>
      },
    },
    {
      field: "n_usage",
      headerName: "Usage Limit",
      flex: 0.5,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        renderCell: (params) => {
            return <Chip size="small" color={`${params.row.active ? `success` : `error`}`} label={`${params.row.active ? `Active` : `Inactive`}`} />
        }
    },
    {
      field: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-voucher/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className={`btn btn-sm bg-gradient-${
                params.row.active ? `danger` : `success`
              }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${params.row.active ? `Deactivate` : `Activate`}`}
              onClick={() => updateStatus(params.row.id, params.row.active)}
            >
              <i className={`fa fa-${params.row.active ? `ban` : `check`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery("vouchers", () => fetchVouchers(), {
    select: (data) => data.vouchers,
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Vouchers</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="d-flex flex-row align-items-center mb-5">
              <a href="/new-voucher" className="btn bg-gradient-primary mr-3">
                <i className="fa fa-plus mr-1" /> New voucher
              </a>
            </div>

            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                slots={{
                  toolbar: GridToolbar,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Vouchers;
