import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchTestimonials, updateTestimonial } from "../../services/";
import { Chip } from "@mui/material";

const Testimonials = () => {
    const { data, isLoading, refetch } = useQuery("testimonials", () => fetchTestimonials(), {
      select: (data) => data.testimonials,
      refetchOnWindowFocus: false,
    });

    const updateStatus = async (id, active) => {
        try {
          await updateTestimonial({ active: +!active }, id);
          alert("Testimonial has been successfully updated");
          window.location.reload();
        } catch (e) {
          if (e.data && e.data.message) alert(e.data.message);
        }
      };

  const columns = [
    {
        field: "photo",
        headerName: "Photo",
        renderCell: (params) => {
            return <img src={params.row.photo} height="100" />
        },
        flex: 0.5,
      },
    {
      field: "title",
      headerName: "Name",
      flex: 0.5,
    },
    {
        field: "content",
        headerName: "Content",
        renderCell: (params) => {
            return <p style={{overflowWrap: "break-word"}}>{params.row.content}</p>
        },
        flex: 1.5,
      },
    {
      field: "status",
      headerName: "Status",
      flex: 0.35,
      renderCell: (params) => {
        let { active } = params.row

        return <Chip color={active ? `success` : `error`} size="small" label={active ? 'Active' : 'Hidden'} />
      }
    },
    {
      field: "date_add",
      headerName: "Created On",
      flex: 0.7,
    },
    {
      field: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row">
            <a
              className="btn btn-sm bg-gradient-dark mr-2"
              href={`/edit-testimonial/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className={`mr-2 btn btn-sm bg-gradient-${
                params.row.active ? `danger` : `success`
              }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${params.row.active ? `Deactivate` : `Activate`}`}
              onClick={() => updateStatus(params.row.id, params.row.active)}
            >
              <i className={`fa fa-${params.row.active ? `ban` : `check`}`} />
            </button>
          </div>
        );
      },
    },
  ];

  /*useEffect(() => {
    refetch()
  },[filters, refetch])*/

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Testimonials</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                {/* <li className="breadcrumb-item active">Dashboard</li> */}
              </ol>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
          <div className="d-flex flex-row align-items-center mb-3">
            <a href="/new-testimonial" className="btn bg-gradient-primary mr-3">
              <i className="fa fa-plus mr-1" /> New testimonial
            </a>
          </div>

          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
            />
          </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
