import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchGallery, uploadFiles, setEventFavMoment, deleteGalleryImage, updateEvent, fetchEvents } from "../../services/";
import { Chip } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const Gallery = () => {
  const [files, setFiles] = useState([]),
    [status, setStatus] = useState(false)

  const uploadRef = useRef(null);
  const { event_id } = useParams();
  
    const { data, isLoading, refetch } = useQuery("gallery", () => fetchGallery(event_id), {
      select: (data) => data.gallery,
      refetchOnWindowFocus: false,
    });

    const { data: eventInfo, isLoading: isEventInfoLoading } = useQuery("info", () => fetchEvents({ id: event_id }), {
      select: (data) => data.events[0],
      refetchOnWindowFocus: false,
    })

  const setMainPhoto = async (file) => {
    try {
      await updateEvent({
        photo: file
      }, event_id);
      window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
      console.log(e);
    }
  }

  const alterFavMoment = async (file) => {
    try {
      await setEventFavMoment({
        photo: file,
      }, event_id);
      alert("Favorite moments successfully updated")
      window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
      console.log(e);
    }
  }

  const removeImage = async (file) => {
    try {
      await deleteGalleryImage(file, event_id);
      window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
      console.log(e);
    }
  }

  const proceed = async (data) => {
    try {
      await uploadFiles(data, event_id);
      // console.log("Submitted");
      window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
      console.log(e);
    }
  };

  if (isLoading || isEventInfoLoading) return <>Loading...</>

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Gallery</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="d-flex flex-row align-items-center mb-3">
            <Formik
              initialValues={{
                gallery: null,
              }}
              onSubmit={(values) => {
                const fd = new FormData();

                for (let index = 0; index < files.length; index++) {
                  fd.append(`gallery`, files[index], files[index].name);
                }

                // fd.append("gallery", files)
                proceed(fd);
              }}
              validationSchema={Yup.object().shape({
                gallery: Yup.mixed().required(
                  "Please select at least one image to upload"
                ),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  setFieldValue,
                  setFieldError,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                      <input
                        type="file"
                        multiple
                        name="gallery"
                        onChange={(e) => {
                          setFieldValue('gallery', e.target.files)
                          setFiles(e.target.files)
                        }}
                      />
                      {errors.gallery && touched.gallery && (
                        <span className="v-error">{errors.gallery}</span>
                      )}

                      {/* <button
                      className="btn bg-gradient-dark mr-2"
                      // onClick={() => {
                      //   uploadRef.current.click()
                      // }}
                    >Select file(s)</button> */}
                    </div>

                    <div className="form-group">
                      <button type="submit" className="btn bg-gradient-success">
                        Upload
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          
          <div className="row">
            {
              data.map((file, ind) => (
                <div className="col-lg-2" key={ind}>
                  <div className="d-block text-center">
                    {/* <img src={`${file.file}`} height="200" alt={file.file} /> */}
                    <img src={`${file.photo}`} height="200" alt={file.photo} />
                  </div>
                  
                  <div className="p-3 d-flex flex-column align-items-center justify-content-center">
                    {
                      // `${process.env.REACT_APP_API_URL}images/events/${event_id}/${file.name}` != `${eventInfo?.photo}`
                      file.photo !== eventInfo?.photo
                      ?
                      <button
                        // onClick={() => setMainPhoto(file.file)}
                        onClick={() => setMainPhoto(file.photo)}
                        className="btn btn-sm bg-gradient-success btn-block"
                      >
                      <i className="fa fa-check" /> Mark main
                    </button>

                    :

                    <button
                        disabled
                        className="btn btn-sm bg-gradient-default btn-block"
                      >
                      <i className="fa fa-check-circle" /> Main photo
                    </button>

                    }

                    <button
                      // onClick={() => alterFavMoment(file.file)}
                      onClick={() => alterFavMoment(file.photo)}
                      className={`btn btn-sm bg-gradient-${file.is_fav ? `danger` : `info`} btn-block`}
                    >
                      <i className={`fa fa-${file.is_fav ? `times` : `check`}`} /> {file.is_fav ? `Unfavorite` : `Mark Favorite`}
                    </button>

                    {/* <label>{`${process.env.REACT_APP_API_URL}images/events/${event_id}/${file.name}`}</label> */}
                    {/* <br /><br /> */}
                    {/* <label>{eventInfo.photo}</label> */}

                    <button onClick={() => removeImage(file.name)} className="btn btn-sm bg-gradient-danger btn-block">
                      <i className="fa fa-trash" /> Delete photo
                    </button>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
